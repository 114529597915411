@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/palatino");
@import url("https://fonts.cdnfonts.com/css/georgia");
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Roboto Condensed", sans-serif;
}
.banner-wrapper {
  /* position: relative; */
  background: #000;
}

.spline-background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spline-background canvas {
  margin: 0 auto;
}
.hideMenuNav {
  display: none;
}
.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  max-width: 330px;
  background: #000;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.experience---wrapper li {
  padding: 0 15px;
}

@media only screen and (max-width: 990px) {
  .desktop-menu {
    display: none;
  }
  .main-wrapper {
    padding: 0;
  }
  .mobile-menu {
    display: block;
  }
  section.mobile-menu .HAMBURGER-ICON {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 99;
  }
}

@media only screen and (min-width: 991px) {
  .mobile-menu {
    display: none;
  }
  .arrow-wrapper {
    left: 62%;
  }
}

@media only screen and (max-width: 800px) {
  .about-heading-wrapper,
  .about-animation-wrapper,
  .contact-image,
  .contact-form-wrap {
    width: 100%;
  }
  .contact-form-wrap {
    padding: 0 20px;
  }
  .about-animation-wrapper lottie-player {
    margin: 0 auto;
  }
  .about-animation-wrapper,
  .skills-wrapper,
  .contact-image {
    margin-bottom: 40px;
  }
  .skills-wrapper {
    margin-top: 40px;
  }
  .skill-list {
    margin: 0 20px;
    /* display: block; */
  }
  .experience---wrapper {
    display: block;
  }
}

.skill-list {
  align-items: center;
  width: 100%;
  margin: 0;
  /* display: block; */
}
.skill-list > div {
  width: 100%;
  /* margin: 20px 0; */
  max-width: 100%;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .banner-wrapper .title {
    font-size: 34px;
    line-height: 45px;
  }
}
.banner-wrapper .title {
  line-height: 60px;
}
.banner-wrapper .title span.background-wrap {
  background: #ffffff8f;
  padding: 0 10px;
  border-radius: 10px;
}
span.Typewriter__cursor {
  color: #5918df;
  font-weight: 800;
  background: #ffffff8f;
  padding: 0 5px;
  border-radius: 10px;
}
.mobile-menu,
.showMenuNav,
.fixed.desktop-menu {
  /* background: linear-gradient(
    180deg,
    rgba(26, 188, 254, 0.6) 0%,
    rgba(193, 222, 226, 0.6) 100%
  ); */
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 8px 32px 0 rgba(124, 58, 237, 0.15);
  /* border-radius: 10px; */
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-left: 0;
  z-index: 9;
}
