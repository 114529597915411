@font-face {
  font-family: "palatino";
  src: local("palatino"), url(./fonts/palatino/Palatino.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto_slab";
  src: local("Roboto_slab"),
    url(./fonts/Roboto_slab/RobotoSlab-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilda_display";
  src: local("Gilda_display"),
    url(./fonts/Gilda_display/GildaDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto_slab";
  font-weight: 900;
  src: local("Roboto_slab"),
    url(./fonts/Roboto_slab/RobotoSlab-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto_slab";
  font-weight: 900;
  src: local("Roboto_slab"),
    url(./fonts/Roboto_slab/RobotoSlab-Black.ttf) format("truetype");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.name {
  /* font-family: Roboto_slab, "Times New Roman", Times, serif; */
  font-weight: 700;
  font-size: larger;
}

/* .head {
  font-family: Roboto_slab, "Times New Roman", Times, serif;
}

.nav {
  font-family: Gilda_display, Arial, serif;
  font-weight: 700;
}

.para {
  font-family: Gilda_display, Arial, serif;
} */

.back {
  background-color: #0f0f0f;
}

.cursor {
  position: fixed;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: white;
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
}

.bg-hero-pattern {
  background-image: url(./assets/images/herobg.png);
}

.guitar {
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.guitar img {
  width: 100%;
  height: 100%;
}

.guitar.hovered {
  /* Apply styles for the guitar when hovered */
  /* Example: add a border or change the background color */
}

audio {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
